@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/Montserrat.ttf') format('truetype');
    font-display: swap;
}

.montserrat-font {
    font-family: 'Montserrat', sans-serif;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Inter', sans-serif;
    overflow-x: hidden;
}

h1 {
    @apply text-3xl py-4 font-bold;
}
h2 {
    @apply text-2xl py-3 font-bold;
}
h3 {
    @apply text-xl py-3 font-bold;
}
p {
    @apply py-2;
}
blockquote {
    @apply p-4 border-s-4 border-gray-300 bg-gray-100 dark:border-gray-500 dark:bg-gray-800;
}
ol {
    @apply list-decimal list-inside;
}
ul {
    @apply list-disc list-inside;
}
pre {
    @apply p-4 bg-gray-900 dark:bg-gray-800;
}
pre code {
    @apply text-gray-100;
}

.data-\[active\=true\]\:after\:bg-black[data-active='true']::after {
    background-color: rgb(235 235 235) !important;
}

p a {
    @apply text-blue-600 decoration-blue-600/30;
}

p a:hover {
    @apply text-blue-800 decoration-blue-800/50;
}
